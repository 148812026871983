<template>
    <!-- 新增算价单 -->
    <div class="box">
        <loading-new :loading="loading"></loading-new>
        <div class="screenSearchPop" style="bottom:0">
            <div v-if="!loading" class="addPeople">
                <control-vue v-if="editSet.length > 0" ref="controlVue1" :editSet="editSet" :optCode="optCode" :moduleCode="moduleCode" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :AONIcustSort="AONIcustSort" :currency="currency" @changeGrassSilkDisabled="changeGrassSilkDisabled" :grassSilk="grassSilk" :billData="addObj"></control-vue>
            </div>
        </div>
    </div>
</template>
<script>
import controlVue from '../vue/index.vue'
import loadingNew from '../../../../Client/CustPublic/LoadingVue/index.vue'
import { mapGetters, mapMutations } from 'vuex'
import Mixin from '@/mixin/index.js'
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    name: 'view1',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    mixins: [Mixin],
    data() {
        return {
            addPeopleFrom: {},
            editSet: [],
            editSet1: [],
            loading: true,
            optName: '新增',
            pageUrl: '',
            grassSilk: ['grassSilk1', 'grassSilk2', 'grassSilk3', 'grassSilk4'],
            addObj: {}
        }
    },
    created() {},
    mounted() {
        this.unit()
    },
    computed: {
        ...mapGetters(['companie'])
    },
    methods: {
        changeGrassSilkDisabled(fieldName) {
            this.editSet.forEach((e) => {
                if (this.grassSilk.includes(e.fieldName)) {
                    e.disabled = fieldName.includes(e.fieldName)
                }
            })
        },
        ...mapMutations({
            set_closeYes: 'SET_CLOSEYES'
        }),
        update() {
            this.editSet = []
            this.editSet1 = []
            this.unit()
        },
        unit() {
            this.pageUrl = this.$route.path
            this.loading = true
            if (this.moduleCode == 'CP010') {
                this.getEditSet()
            } else {
                this.getEditSetOther()
            }
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(
                    this.$t('mx_mobile.Client.1585301134763')
                ) // 保存
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        getEditSet() {
            // 获取数据
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                _this.axios
                    .get(
                        this.Global.config.apiBaseURL +
                            this.Global.api.v2.fieldShow_get,
                        {
                            params: {
                                moduleCode: 'CP010',
                                type: 'addEditSet'
                            }
                        }
                    )
                    .then(
                        function (res) {
                            if (
                                res.data.code.toString() ==
                                _this.Global.config.RES_OK
                            ) {
                                resolve(_this.swidchEditSet(res.data.data))
                            } else {
                                _this.loading = false
                                _this.$toast.fail(res.data.msg)
                            }
                        },
                        function (res) {
                            _this.loading = false
                            _this.$toast.fail(_this.Global.config.errorTitle)
                        }
                    )
            })
            Promise.all([p1]).then(function (results) {
                const editSet = results[0]
                const curRoles = _this.companie.contactRoles
                console.log('curRoles', curRoles)
                const displayHiddenFields = ['fullCostAmt', 'compFullCostAmt']
                const displayHiddenTabFields = ['bottomCost', 'bottomCoefficient', 'silkCost1', 'silkCost2', 'silkCost3', 'silkCost4', 'silkCoefficient1', 'silkCoefficient2', 'silkCoefficient3', 'silkCoefficient4', 'maxDosage', 'maxCoefficient', 'grassa', 'grassb', 'grassc', 'grassd', 'tuftingCostTax', 'tuftingCost', 'backglueCostTax', 'backGlueCost', 'bottomPrice', 'lawnStandard', 'grassSilkSales', 'lawnSales', 'calculateWeight']
                const showFieldsRoles = [1, 2, 4, 11482, 10584]
                const showTabRoles = [1, 2, 11356]
                let displayHiddenFieldsFlag = true
                let displayHiddenTabFlag = true
                curRoles.forEach(el => {
                    if (showTabRoles.includes(el)) {
                        displayHiddenTabFlag = false
                    }
                    if (showFieldsRoles.includes(el)) {
                        displayHiddenFieldsFlag = false
                    }
                })
                editSet.forEach(el => {
                    el.isDisplayHidden = false
                    if (displayHiddenFields.includes(el.fieldName) && displayHiddenFieldsFlag) {
                        el.isDisplayHidden = true
                    }
                    if ((displayHiddenTabFields.includes(el.fieldName) || ['详细信息2'].includes(el.cnFieldCaption)) && displayHiddenTabFlag) {
                        el.isDisplayHidden = true
                    }
                    if (['custName'].includes(el.fieldName)) {
                        el.isDisplayHidden = true
                    }
                    if (el.fieldId == 222110 && el.cnFieldCaption == '详细信息1') {
                        el.isDisplayHidden = true
                    }
                    if (el.fieldId == 222113 && el.cnFieldCaption == '详细信息2') {
                        el.isDisplayHidden = true
                    }
                    if ([4, 5].includes(el.fieldGroup)) {
                        el.isDisplayHidden = true
                    }
                })
                _this.editSet = editSet.filter(ele => ele && ele.isEditShow != 2)
                _this.loading = false
                let addObj = _this.Global.utils.getItem('addPriceCalcObjCP010')
                if (addObj) {
                    _this.addObj = JSON.parse(addObj)
                    setTimeout(() => {
                        _this.$refs.controlVue1.updates(addObj)
                        _this.Global.utils.removeItem('addPriceCalcObjCP010')
                    }, 20)
                }
            })
        },
        getEditSetOther() {
            // 获取数据
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                _this.axios
                    .get(
                        this.Global.config.apiBaseURL +
                            this.Global.api.v2.fieldShow_get,
                        {
                            params: {
                                moduleCode: _this.moduleCode,
                                type: 'addEditSet'
                            }
                        }
                    )
                    .then(
                        function (res) {
                            if (
                                res.data.code.toString() ==
                                _this.Global.config.RES_OK
                            ) {
                                resolve(_this.swidchEditSet(res.data.data))
                            } else {
                                _this.$toast.fail(res.data.msg)
                            }
                        },
                        function (res) {
                            _this.$toast.fail(_this.Global.config.errorTitle)
                        }
                    )
            })
            Promise.all([p1]).then(function (results) {
                _this.editSet = results[0]
                _this.editSet1 = []
                _this.loading = false
                let addObj = _this.Global.utils.getItem(
                    'addPriceCalcObj' + _this.moduleCode
                )
                if (addObj) {
                    let addObj1 = addObj
                    _this.addObj = JSON.parse(addObj)
                    setTimeout(function () {
                        _this.$refs.controlVue1.updates(addObj1)
                        _this.Global.utils.removeItem(
                            'addPriceCalcObj' + _this.moduleCode
                        )
                    }, 20)
                }
            })
        },
        swidchEditSets(list) {
            let newLList = []
            list.forEach((element) => {
                element.disabled = this.fieldIsEditable(
                    this.optCode,
                    element.editState
                )
                newLList.push(element)
            })
            return newLList
        },
        swidchEditSet(list) {
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                element.controlData = ''
                element.disabled = this.fieldIsEditable(
                    this.optCode,
                    element.editState
                )
                element.fieldValue = element.inDefaultValue
                if (element.fieldGroup == 20) {
                    element.fieldGroup = 0 // 拆分组
                }
                if (element.fieldGroup !== 0) {
                    // 判断是否是一个组
                    let isHave = false
                    let thisGroup = ''
                    groupFirst.forEach((item) => {
                        // 判断这个组是否出现过，出现则在groupFirst里做个标记
                        if (item == element.fieldGroup) {
                            isHave = true
                            thisGroup = item // 记住这个组id
                        }
                    })
                    if (!isHave) {
                        // 如果没有出现过新建一个对象放入newList里面
                        let newObj = {
                            fieldGroup: element.fieldGroup,
                            cnFieldCaption: '',
                            isTop: element.isTop,
                            group: []
                        }
                        newObj.group.push(element)
                        newLList.push(newObj)
                        groupFirst.push(element.fieldGroup)
                    } else {
                        // 如果出现过就找之前创建的对象将自己放入到group数组中
                        newLList.forEach(function (them) {
                            if (them.fieldGroup == thisGroup) {
                                them.group.push(element)
                            }
                        })
                    }
                } else {
                    newLList.push(element)
                }
            })
            return newLList
        },
        submitAddFrom() {
            // 提交新增客户数据
            let data1
            if (this.$refs.controlVue1) {
                data1 = this.$refs.controlVue1.submit()
            }
            if (data1) {
                this.submitAdd(this.checkBe(data1))
            }
        },
        submitAdd(data1, data2) {
            console.log('submitAdd', data1)
            let _this = this
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.getFormKey((formKey, msg) => {
                if (formKey && formKey != '') {
                    if (this.moduleCode == 'CP010') {
                        _this.axios
                            .post(
                                this.Global.config.apiBaseURL +
                                    _this.Global.api.v2.document_quotation_post,
                                {
                                    ...data1,
                                    moduleCode: this.moduleCode,
                                    formKey: formKey
                                }
                            )
                            .then(
                                function (res) {
                                    _this.$toast.clear()
                                    if (
                                        res.data.code.toString() ==
                                        _this.Global.config.RES_OK
                                    ) {
                                        _this.$toast.success(res.data.msg)
                                        _this.set_closeYes(false)
                                        _this.$router.back()
                                    } else {
                                        _this.$toast.fail(res.data.msg)
                                    }
                                },
                                function (res) {
                                    _this.$toast.clear()
                                    _this.$toast.fail(
                                        _this.Global.config.errorTitle
                                    )
                                }
                            )
                    }
                } else {
                    _this.$toast.clear()
                    _this.$toast.fail(msg)
                }
            })
        },
        checkBe(obj) {
            if (!(typeof obj == 'object')) {
                return
            }
            for (var key in obj) {
                if (
                    obj.hasOwnProperty(key) &&
                    (obj[key] == null ||
                        obj[key] == undefined ||
                        obj[key] == '')
                ) {
                    delete obj[key]
                }
            }
            return obj
        }
    },
    watch: {},
    components: {
        'control-vue': controlVue,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
